//imports
import { Scene, WebGLRenderer, PCFSoftShadowMap, SRGBColorSpace, NeutralToneMapping, LinearToneMapping, CineonToneMapping, ACESFilmicToneMapping, ReinhardToneMapping, PerspectiveCamera, Cache, EquirectangularReflectionMapping, VSMShadowMap } from 'three';
import * as lights from './lights';
import { OrbitControls } from '../OrbitControls';
import { mobile } from '../helpers/helpers';
import { InteractionManager } from 'three.interactive';
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';

// Three cache
Cache.enabled = true;

// scene
let scene = new Scene();

// renderer
let renderer = new WebGLRenderer({ alpha: false, antialias: true, preserveDrawingBuffer: true, powerPreference: 'low-power' });
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(1)
renderer.capabilities.getMaxAnisotropy()
renderer.capabilities.getMaxPrecision()
renderer.setClearColor(0xffffff, 1);
renderer.gammaOutput = true;
renderer.shadowMap.enabled = true
renderer.shadowMap.autoUpdate = true;
renderer.shadowMap.needsUpdate = true;
renderer.shadowMap.type = PCFSoftShadowMap; // PCFSoftShadowMap
renderer.outputColorSpace = SRGBColorSpace;
renderer.toneMapping = CineonToneMapping;
renderer.toneMappingExposure = 1.65; // 1.65 production
renderer.localClippingEnabled = true;
document.body.prepend(renderer.domElement);

// cameras
const camera = new PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.x = 0;
camera.position.z = 4;

const cameraSecond = new PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.x = 1;
camera.position.y = 1;
camera.position.z = 4;




const camera2 = new PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.1, 1000);
camera2.position.set(-1.4, -0.8, 2);
camera2.lookAt(4, -1, 0.5);

const camera3 = new PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.1, 1000);
camera3.position.set(1.2, 1.5, 0.6);
camera3.lookAt(0, 1, 0);

const camera4 = new PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.1, 1000);
camera4.position.set(0, 1, 2.2);
camera4.lookAt(0, 0, 0);

const cameras = [camera, camera2, camera3, camera4];

// lights
scene.add(lights.ambiLight);
scene.add(lights.hemiLight);
scene.add(lights.directLight);
scene.add(lights.backLight);

// environment HDR map
let standaloneHDR
new RGBELoader().setPath('images/').load('hdr3.hdr', function (hdrmap) {
    hdrmap.mapping = EquirectangularReflectionMapping;
    scene.environment = hdrmap
    standaloneHDR = hdrmap
})

// orbit controls
const oControls = new OrbitControls(camera, renderer.domElement);
oControls.enablePan = false;
oControls.rotateSpeed = 0.025; // 0.025
oControls.enableZoom = true;
oControls.zoomToCursor = true;
oControls.maxDistance = 4;
oControls.minDistance = 1;
oControls.enableDamping = true;
oControls.dampingFactor = .05;
oControls.maxPolarAngle = Math.PI / 2;
oControls.minPolarAngle = Math.PI / 2;
oControls.minAzimuthAngle = -(Math.PI / 5) // 20
oControls.maxAzimuthAngle = (Math.PI / 5)  // 20
oControls.enableRotate = false;

if (mobile) {
    oControls.screenSpacePanning = true;
    oControls.touches.ONE = THREE.TOUCH.PAN;
    oControls.touches.TWO = THREE.TOUCH.DOLLY_ROTATE;
    oControls.minAzimuthAngle = 0
    oControls.maxAzimuthAngle = 0
}

// interaction manager init
const interactionManager = new InteractionManager(renderer, camera, renderer.domElement);


export {
    cameraSecond,
    camera2,
    camera3,
    scene,
    renderer,
    camera,
    cameras,
    oControls,
    interactionManager,
    standaloneHDR
}
import.meta.glob([
    '../images/**',
    '../fonts/**',
    '../three/**',
]);

import './bootstrap';
import { standTypes, activeStandType } from "./standTypes";
import * as screenshot from './screenshot';
import * as measurements from './measurements';
import * as fullscreen from './fullscreen';
import * as THREE from 'three';
import * as materials from './materials';
import * as functions from './functions';
import { mobile, onPointerMove, pointer, viewport, autoresize } from './helpers/helpers';
import { scene, renderer, camera, cameraSecond, camera2, camera3, oControls, interactionManager, standaloneHDR } from './config/scene';
import { gsap } from "gsap";
import { DragControls } from './DragControls';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { Text } from 'troika-three-text'
import 'ldrs/ring';
import { directLight, backLight } from './config/lights';

// Check if there is configuration to be loaded
let configurationToLoad = false
$(document).ready(function () {
    if (getUrlParameter('config')) {
        configurationToLoad = true
        Livewire.dispatch('get-hash', { hash: getUrlParameter('config') })
        getConfigurationData(getUrlParameter('config'))
    } else {
        Livewire.dispatch('activate-app')
    }
});


// Global variables init
let dragInstance = {},
    wallSwitch = false,
    objectControlsActive = false,
    objectsVisible = true,
    mixer,
    introAnimation = true,
    addedObjects = [],
    ModelloadingInProgress = false,
    wallsArray = [],
    rightProps,
    objectsMoveConstraints = [],
    binButtonModel,
    configModelsCount = 0,
    configModelsLoaded = 0,
    aiModelsCount = 0,
    cutButtonModel,
    loadingManager = new THREE.LoadingManager(),
    dLoader = new DRACOLoader(),
    gLoader = new GLTFLoader(loadingManager),
    globalColorVariation = 'steel',
    loadedConfigType = '',
    nightLights = [],
    configurationHash = '',
    userAuthorized = sessionStorage.getItem('authorization') ? sessionStorage.getItem('authorization') : '0',
    configurationsArray = JSON.parse(sessionStorage.getItem('configurations')) ? JSON.parse(sessionStorage.getItem('configurations')) : []

// Alpine store
document.addEventListener('alpine:init', () => {

    Alpine.store('activeStandType', {
        val: activeStandType
    })

    Alpine.store('ai', {
        on: false,
        toggle() { this.on = !this.on }
    })

    // if app should load starter component
    Alpine.store('starter', {
        on: true,
        toggle() { this.on = !this.on }
    })

    // if app is loaded in an iframe
    Alpine.store('external', {
        on: false,
        toggle() { this.on = !this.on }
    })

    // information about where iframe is located
    Alpine.store('externalDestination', {
        val: 'reponio_official'
    })

    // allow eshop features
    Alpine.store('allowShop', {
        on: true,
        toggle() { this.on = !this.on }
    })
    Alpine.store('conForm', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('confNo', {
        val: ''
    })

    Alpine.store('saveReminder', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('confSettings', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('cartModal', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('auth', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('uiSearchState', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('mobileMenu', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('mobileCart', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('mobileControls', {
        on: false,
        toggle() { this.on = !this.on }
    })

    Alpine.store('colorBar', {
        on: false,
        toggle() {
            this.on = !this.on

            if (this.on) {
                $('#color-panel-toggle').html('Close')
            } else {
                $('#color-panel-toggle').html('Change color')
            }
        }
    })

    Alpine.effect(() => {
        // Update the button text when colorBar.on changes
        $('#color-panel-toggle').text(
            Alpine.store('colorBar').on ? 'Close' : 'Change color'
        );
    });

    // customizes UI if app is loaded in an iframe
    const urlParams = new URLSearchParams(window.location.search);
    const iframeDestination = urlParams.get('destination');
    const iframeShop = urlParams.get('shop');
    const introScreen = urlParams.get('intro');
    const configParameter = urlParams.get('config');

    if (introScreen === 'false' || configParameter !== null) {
        Alpine.store('starter').on = false
    } else {
        Livewire.dispatch('starter-init')
    }

    if (iframeShop !== null && iframeShop === 'false') {
        console.log('test')
        Alpine.store('allowShop').on = false
        $('.legal_container').fadeOut('fast')
    }

    if (iframeDestination !== null) {
        console.log('test')
        Alpine.store('external').on = true
        Alpine.store('allowShop').on = false
        Alpine.store('externalDestination').val = iframeDestination
        $('.legal_container').fadeOut('fast')
        $('.head_logo').css('display', 'none').css('important', 'display');
        $('.legal_container').fadeOut('fast')
        $('#cart_component').css('display', 'none').css('important', 'display');

        if (iframeShop !== null && iframeShop === 'true') {
            Alpine.store('allowShop').on = true
            $('.head_logo').fadeOut('fast')
            $('.legal_container').fadeOut('fast')
            $('#cart_component').fadeOut('fast')
        }
    }
})


Livewire.on('unlock-configuration', ({ hash }) => {
    configurationHash = hash
    Alpine.store('conForm').toggle()
    Alpine.store('auth').toggle()
    Alpine.store('confNo').val = hash
    Livewire.dispatch('get-conf-number', { confNumber: hash })
})

// THREE Loading manager functions
loadingManager.onStart = function (url, item, total) { }
loadingManager.onProgress = function (url, loaded, total) {
    document.getElementById('progress-bar').value = (loaded / total) * 100
}
loadingManager.onLoad = function () {
    Livewire.dispatch('load-objects-data')
    $('.progress-bar-container').fadeOut('slow')
}

// Draco loader setup
dLoader.setDecoderPath('js/draco/');
gLoader.setDRACOLoader(dLoader)

// INITIAL ANIMATED PANELS
let lastWallX = 1.86
gLoader.load(
    "images/ChytryStojan.glb",
    function (gltf) {
        let s = 1;
        gltf.scene.scale.set(s, s, s);

        gltf.scene.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
                if (child.name.indexOf('panel') !== -1) {
                    child.material = materials.panelSurface;
                    child.material.envMap = standaloneHDR;
                }
                if (child.name.indexOf('metal') !== -1) {
                    child.material = materials.metalMat;
                }
                if (child.name.indexOf('podstavec') !== -1) {
                    child.material = materials.podstavecMat;
                    child.material.envMap = standaloneHDR;
                }

                if (child.name.indexOf('frame') !== -1) {
                    child.material = materials.frameMat;
                    child.material.envMap = standaloneHDR;
                }

                child.castShadow = true;
                child.receiveShadow = true;
            }
        });

        gltf.scene.position.z = 0
        gltf.scene.position.y = 0
        gltf.scene.position.x = 0



        // adding wall to wallsArray for further manipulation and organization
        let firstWallBoundingBox = new THREE.Box3().setFromObject(gltf.scene)
        firstWallBoundingBox.applyMatrix4(scene.matrixWorld)

        lastWallX = firstWallBoundingBox.max.x

        let wall = {
            'id': 1,
            'size': 2,
            'xMin': -1.96,
            'xMax': 1.96,
            'group': new THREE.Group()
        }

        wall.group.userData.switched = false

        wall.group.add(gltf.scene)
        wallsArray.push(wall)

        scene.add(wall.group)

        oControls.maxPan = new THREE.Vector3(lastWallX - 1.9718, 0, 0);
        oControls.enablePan = true;

        let timeOut
        configurationToLoad ? timeOut = 0 : timeOut = 10000

    },
    function (xhr) { },
    function (error) { }
);

let branding;
gLoader.load(
    "images/branding-type-01.glb",
    function (gltf) {
        let s = 1;

        branding = gltf.scene;

        branding.scale.set(s, s, s);

        branding.position.z = 0
        branding.position.y = 0
        branding.position.x = 0
        branding.material.map.minFilter = THREE.LinearFilter;

    },
    function (xhr) { },
    function (error) { }
);

let brandingState = false;

$('#branding_add_btn').on('click', function () {


    if (!brandingState) {
        loadBranding();
        $('#branding_add_btn').addClass('active')
    } else {
        unloadBranding();
        $('#branding_add_btn').removeClass('active')
    }

    brandingState = !brandingState
})

function unloadBranding() {
    scene.remove(branding)
}

function loadBranding() {
    scene.add(branding)
}

// control panel color
let activePanelColor = '4F5358'
let tempColor = new THREE.Color().setHSL(0, 0, 0);
$('.change-panel-color-btn').on('click', function () {
    //e.preventDefault()
    let colorName = $(this).data('name');
    tempColor.setHex('0x' + $(this).data('color'));
    let newColor = $(this).data('color')
    activePanelColor = newColor
    scene.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
            if (child.name.indexOf('panel') !== -1) {
                if (newColor === '989EA2') { // dark wood
                    child.material = materials.darkWoodSurface
                    gsap.to(materials.panelSurface.color, {
                        r: tempColor.r,
                        g: tempColor.g,
                        b: tempColor.b,
                        duration: .25
                    })
                }
                if (newColor === '989EA3') { // light wood
                    child.material = materials.lightWoodSurface
                    gsap.to(materials.panelSurface.color, {
                        r: tempColor.r,
                        g: tempColor.g,
                        b: tempColor.b,
                        duration: .25
                    })
                }
                if (newColor !== '989EA3' && newColor !== '989EA2') {
                    child.material = materials.panelSurface
                    gsap.to(child.material.color, {
                        r: tempColor.r,
                        g: tempColor.g,
                        b: tempColor.b,
                        duration: .25
                    })
                }

            }
        }
    });
    $('#color-preview-ico').css('background-color', "#" + $(this).data('color'))
    Livewire.dispatch('panel-color-change', { shop_id: $(this).data('id'), color: colorName })
})

gLoader.load(
    'images/hall.glb',
    function (gltf) {
        let studio = gltf.scene
        let s = 1
        studio.scale.set(s, s, s)
        studio.position.y = 0
        studio.position.z = 0 // -3.5

        studio.traverse((child) => {
            console.log(child)
            if (child.isMesh) {
                child.material = materials.interiorMat;
            }
            child.castShadow = true;
            child.receiveShadow = true;
        });

        studio.name = 'studio'
        scene.add(studio)
    },
    function (xhr) { },
    function (error) { }
);

function loadInterfaceModel(model) {
    if (model === 'button_bin') {
        gLoader.load(
            "images/" + model + ".glb",
            function (gltf) {
                binButtonModel = gltf.scene
                binButtonModel.traverse(function (child) {
                    if (child instanceof THREE.Mesh) {
                        if (child.name === 'buttonwhite') {
                            child.material = materials.whiteShinyMat;
                        }
                        if (child.name === 'bin') {
                            child.material = materials.redShinyMat;
                        }
                        child.castShadow = false;
                        child.receiveShadow = true;
                    }
                });
                scene.add(binButtonModel)
            },
            function (xhr) { },
            function (error) { }
        );
    }
    if (model === 'button_cut') {
        gLoader.load(
            "images/" + model + ".glb",
            function (gltf) {
                cutButtonModel = gltf.scene
                cutButtonModel.traverse(function (child) {
                    if (child instanceof THREE.Mesh) {
                        if (child.name === 'blueButtonBg') {
                            child.material = materials.objMetalDark;
                        }
                        if (child.name === 'cut_ico') {
                            child.material = materials.whiteShinyMat;
                        }
                        child.castShadow = false;
                        child.receiveShadow = true;
                    }
                });
                cutButtonModel.position.y = -10
                scene.add(cutButtonModel)
            },
            function (xhr) { },
            function (error) { }
        );
    }
}

if (activeStandType.name === 'wall') {
    loadInterfaceModel('button_bin')
    loadInterfaceModel('button_cut')
}




let lastWall

// function to load additional panel sets(walls)
async function loadWall(size) {
    gLoader.load(
        "images/wall_" + size + "m.glb",
        function (gltf) {
            let s = .05;

            gltf.scene.scale.set(s, s, s);

            gltf.scene.traverse(function (child) {
                if (child instanceof THREE.Mesh) {
                    if (child.name.indexOf('panel') !== -1) {
                        if (activePanelColor === '989EA2') { // dark wood
                            child.material = materials.darkWoodSurface
                        }
                        if (activePanelColor === '989EA3') { // light wood
                            child.material = materials.lightWoodSurface
                        }
                        if (activePanelColor !== '989EA3' && activePanelColor !== '989EA2') {
                            child.material = materials.panelSurface;
                        }
                    }
                    if (child.name.indexOf('metal') !== -1) {
                        child.material = materials.panelSurface;
                    }
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            });

            gltf.scene.position.z = 0.13
            gltf.scene.position.y = 0.165
            gltf.scene.name = 'wall'

            // get last wall properties in wallsArray in order to place next wall properly
            // 2m wall size = 3.9437
            // 1m wall size = 1.9718
            // sideBarWidth = .06

            lastWall = wallsArray[wallsArray.length - 1]
            let lastWallBoundingBox = lastWall.xMax
            let lastWallGroupBoundingBox = new THREE.Box3().setFromObject(lastWall.group)
            //lastWallBoundingBox.applyMatrix4(scene.matrixWorld)

            gltf.scene.position.x = 1.9718 * (size / 2) + lastWallBoundingBox - 0.06
            //gltf.scene.position.x = 1.9718 * (size / 2) + lastWallBoundingBox.max.x - 0.06

            lastWallX = lastWallBoundingBox + (1.9718 * size) - 0.12
            oControls.enablePan = true;

            // get minX and maxX of this wall
            let thisWallBoundingBox = new THREE.Box3().setFromObject(gltf.scene)
            thisWallBoundingBox.applyMatrix4(scene.matrixWorld)
            let wallCenterX = thisWallBoundingBox.min.x + (thisWallBoundingBox.max.x - thisWallBoundingBox.min.x) / 2
            let wallCenterY = thisWallBoundingBox.min.y + (thisWallBoundingBox.max.y - thisWallBoundingBox.min.y) / 2

            gsap.to(oControls.target, { x: wallCenterX, y: wallCenterY, duration: 1.5, ease: "power2.inOut" })
            gsap.to(camera.position, { x: wallCenterX, y: wallCenterY, duration: 1.5, ease: "power2.inOut" })
            gsap.to(rightProps.position, { x: rightProps.position.x + 1.9718 * (size / 2), duration: 1.5, ease: "power2.inOut" })

            // add delete button object
            let deleteBtnMOdel = binButtonModel.clone()
            deleteBtnMOdel.position.x = thisWallBoundingBox.min.x + (thisWallBoundingBox.max.x - thisWallBoundingBox.min.x) / 2
            deleteBtnMOdel.position.y = thisWallBoundingBox.min.y
            deleteBtnMOdel.position.z = .25
            deleteBtnMOdel.name = 'delete'

            // adding wall to wallsArray for further manipulation and organization

            let wall = {
                'id': functions.getRandomInt(2, 9999),
                'size': size,
                'xMin': thisWallBoundingBox.min.x,
                'xMax': thisWallBoundingBox.max.x,
                'group': new THREE.Group()
            }

            wall.group.userData.switched = false

            wall.group.add(deleteBtnMOdel)
            wall.group.add(gltf.scene)

            wallsArray.push(wall)

            scene.add(wall.group)


            // update clipping plane
            updateClipPlane(wall.xMin, wall.xMax, wall.group)

            // add wall delete button
            let deleteBtn = wall.group.getObjectByName('delete')
            interactionManager.add(deleteBtn);

            //removes wall and all objects on it
            deleteBtn.addEventListener('click', (event) => {

                wallSwitch = false;

                let deleteWall = wallsArray.find(x => x.id === wall.id)
                let wallIndex = wallsArray.indexOf(deleteWall)

                let deleteBtn = wall.group.getObjectByName('delete')
                scene.remove(deleteBtn)

                Livewire.dispatch('delete-cart-item', { wallSize: deleteWall.size, wallSwitched: deleteWall.group.userData.switched })

                // rearange rest of walls after the one being deleted
                if (wallsArray.length - 1 > wallIndex && wallsArray.length > 2) {
                    for (let i = wallIndex + 1; i <= wallsArray.length - 1; i++) {
                        wallsArray[i].group.position.x -= 1.9718 * deleteWall.size - 0.06

                        let bBox = new THREE.Box3().setFromObject(wallsArray[i].group)
                        bBox.applyMatrix4(scene.matrixWorld)

                        wallsArray[i].xMin = bBox.min.x
                        wallsArray[i].xMax = bBox.max.x
                    }
                }

                interactionManager.remove(wall.group);

                wallsArray.splice(wallIndex, 1);

                lastWall = wallsArray[wallsArray.length - 1]
                lastWallBoundingBox = new THREE.Box3().setFromObject(lastWall.group)
                lastWallBoundingBox.applyMatrix4(scene.matrixWorld)

                lastWallX = lastWall.xMax
                let lastWallCenterX = lastWallBoundingBox.min.x + (lastWallBoundingBox.max.x - lastWallBoundingBox.min.x) / 2
                let lastWallCenterY = lastWallBoundingBox.min.y + (lastWallBoundingBox.max.y - lastWallBoundingBox.min.y) / 2

                oControls.maxPan = new THREE.Vector3(lastWallBoundingBox.max.x - 1.9718, 0, 0);

                gsap.to(oControls.target, { x: lastWallCenterX, y: lastWallCenterY, duration: 1.5, ease: "power2.inOut" })
                gsap.to(camera.position, { x: lastWallCenterX, y: lastWallCenterY, duration: 1.5, ease: "power2.inOut" })

                gsap.to(rightProps.position, { x: rightProps.position.x - 1.9718 * deleteWall.size / 2, duration: 1.5, ease: "power2.inOut" })

                async function wallObjectsDeletion() {
                    let noOfChildren = deleteWall.group.children.length
                    for (let i = 0; i <= deleteWall.group.children.length - 1; i++) {
                        let child = deleteWall.group.children[i]
                        if (child.name === 'delete') {
                            deleteWall.group.remove(child)
                        }

                        if (child.name !== 'wall' && child.name !== 'delete') {
                            Livewire.dispatch('delete-cart-item', { removeItemId: parseInt(child.name), type: 'one', amount: 1, priceId: child.userData.priceId })
                            //deleteWall.group.remove(child)
                            await unloadSingleModel(deleteWall.id, 'wall')
                            dragInstance['drag' + child.id].dispose()
                        }



                        if (i === noOfChildren - 1) {
                            return new Promise(resolve => {
                                resolve()
                            })
                        }
                    }
                }

                wallObjectsDeletion().then((resolve) => {
                    updateClipPlane(lastWall.xMin, lastWall.xMax)

                })

                scene.remove(wall.group)

            });

        },
        //function (xhr) { console.log((xhr.loaded / xhr.total * 100) + '% loaded'); },
        //function (error) { console.log('An error happened'); }
    );

    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, 2000);
    });
}

Livewire.on('addWall', ({ size: size }) => {
    loadWall(size)
})


// add clipping button object
let rightClipPlane
let topClipPlane
let wallControlObjects = []
let wallBorder
let systemXValue = new Text()
let systemXMinValue
let systemXMaxValue
systemXValue.text = '200 cm'
systemXValue.fontSize = 0.075
systemXValue.fontWeight = 'bold'
systemXValue.color = 0x222222


// show hide initial 2m wall in cart
Livewire.on('toggleWallFromCart', ({ show }) => {
    if (show) {
        console.log(show)
        $(document.getElementsByClassName('cart_item')[0]).fadeIn(10)
    } else {
        console.log(show)
        $(document.getElementsByClassName('cart_item')[0]).fadeOut(10)
    }
})

// function to load and place models/objects on demand
let trueHover = false
let controlsHovered = false
function loadModel(model, product, params, offset, obj, colorVariation, priceId, originalObjId, configPositions, objSceneId, rotation = 1, ai = false) {
    gLoader.load(
        model,
        function (gltf) {

            let objects = []

            objectsVisible = true;

            let object = gltf.scene

            object.scale.set(0.55, 0.55, 0.55)

            let objectDefaultZ = activeStandType.objectZ

            let boundingBox = new THREE.Box3().setFromObject(object);
            let width = boundingBox.max.x - boundingBox.min.x;
            let height = boundingBox.max.y - boundingBox.min.y;


            object.userData.type = "model"
            object.userData.priceId = priceId
            object.userData.modelPath = model
            object.userData.params = params.toString()
            object.userData.rotation = rotation
            object.userData.switchMode = false

            if (model.includes('kolo') && !model.includes('vidula') && !model.includes('kolobezka_velka') && !model.includes('elektro') && !model.includes('dospele_kolo') && !model.includes('detske_kolo') && !model.includes('kolo_helma')) {
                object.userData.switchMode = true

                let modelPart = object.getObjectByName('kolo_rotate')

                if (object.userData.rotation === -1) {
                    modelPart.rotation.z = -modelPart.rotation.z
                }
            }

            if (model.includes('dospele_kolo') || model.includes('kolobezka_velka')) {
                object.userData.switchMode = true

                let modelPart = object.getObjectByName('kolo_rotate_y')

                if (object.userData.rotation === 1) {
                    modelPart.rotation.y = 0
                }

                if (object.userData.rotation === -1) {
                    modelPart.rotation.y = Math.PI
                }
            }

            object.traverse(function (child) {
                if (child instanceof THREE.Mesh) {
                    if (child.name.indexOf('metal') !== -1) {
                        if (model.indexOf('store') !== -1) {
                            object.userData.colorVariation = colorVariation ? colorVariation : 'black'
                        } else {
                            object.userData.colorVariation = colorVariation ? colorVariation : globalColorVariation
                        }


                        let metalMaterialVariationColor;

                        if (object.userData.colorVariation === 'steel') {
                            metalMaterialVariationColor = 0xF3F0EA
                        }

                        if (object.userData.colorVariation === 'black') {
                            metalMaterialVariationColor = 0x2B2B2C
                        }

                        if (object.userData.colorVariation === 'white') {
                            metalMaterialVariationColor = 0xFFFFFF
                        }


                        let metalMaterial = new THREE.MeshPhysicalMaterial({
                            color: metalMaterialVariationColor,
                            metalness: object.userData.colorVariation === 'white' ? 0 : 1,
                            reflectivity: 0.85,
                            roughness: .22,
                            ior: 1.7
                        })

                        child.material = metalMaterial;
                    }
                    if (child.name.indexOf('rubber') !== -1) {
                        object.userData.colorVariation = colorVariation ? colorVariation : globalColorVariation

                        let rubberMaterial = new THREE.MeshPhysicalMaterial({
                            color: object.userData.colorVariation === 'steel' ? 0x000000 : 0x7F786A,
                        })

                        child.material = rubberMaterial;
                    }
                    if (child.name === 'model') {
                        child.material = materials.objectMat;
                        gsap.to(child.material, { opacity: 1, duration: 0.4 })
                    }
                    if (child.name.indexOf('hbox') !== -1) {
                        child.material = materials.hoverMat;
                    }
                    if (child.name.indexOf('fortis') !== -1) {

                        let fortisMaterial = new THREE.MeshPhysicalMaterial({
                            color: 0xffffff,
                            metalness: object.userData.colorVariation === 'white' ? 0.65 : 1,
                            reflectivity: 0.9,
                            roughness: .15,
                            ior: 1.7
                        })

                        child.material = fortisMaterial;
                    }
                    if (child.name.indexOf('tag') !== -1) {
                        child.material = materials.tagMaterial;
                    }
                    if (child.name.indexOf('greenglass') !== -1) {
                        child.material = materials.greenglassMat;
                    }
                    if (child.name.indexOf('blackmet') !== -1) {
                        child.material = materials.blackMetal;
                    }
                    if (child.name.indexOf('plastic') !== -1) {
                        object.userData.colorVariation = colorVariation ? colorVariation : 'white'

                        let plasticColor
                        let plasticTransmission = 0
                        if (object.userData.colorVariation === 'white') { plasticColor = 0xBEBEBE }
                        if (object.userData.colorVariation === 'grey') { plasticColor = 0x666666 }
                        if (object.userData.colorVariation === 'black') { plasticColor = 0x000000 }
                        if (object.userData.colorVariation === 'red') { plasticColor = 0xff0000 }
                        if (object.userData.colorVariation === 'blue') { plasticColor = 0x00197E }
                        if (object.userData.colorVariation === 'graphite') { plasticColor = 0x111111 }
                        if (object.userData.colorVariation === 'transparent') { plasticColor = 0xffffff, plasticTransmission = 0.1 }

                        let plasticMaterial = new THREE.MeshPhysicalMaterial({
                            color: plasticColor,
                            reflectivity: .6,
                            roughness: 0.25,
                            clearcoat: .15,
                            clearcoatRoughness: 0.15,
                            transmission: plasticTransmission
                        })

                        child.material = plasticMaterial;
                    }
                    if (child.name === 'mic') {
                        child.material.roughness = .6;
                        child.material.metalness = 0;
                        gsap.fromTo(child.rotation, { y: 2 }, { y: 0, duration: 1, ease: "power3.out" })
                    }
                    if (child.name.indexOf('m_met') !== -1) {
                        child.material = materials.objMetal;
                    }
                    if (child.name.indexOf('plastorange') !== -1) {
                        child.material = materials.plasticOrangeMat;
                    }
                    if (child.name.indexOf('m_met_dark') !== -1) {
                        child.material = materials.objMetalDark;
                    }
                    if (child.name.indexOf('m_rub') !== -1) {
                        child.material = materials.objRubber;
                    }
                    if (child.name.indexOf('m_white') !== -1) {
                        child.material = materials.objWhite;
                    }
                    if (child.name.indexOf('m_bike_body') !== -1) {
                        child.material = materials.objDark;
                    }
                    if (child.name.indexOf('m_kufr_body') !== -1) {
                        child.material = materials.kufrBodyMat;
                    }
                    if (child.name !== 'hbox') {
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }

                }
            });

            // pass product iD as object name
            object.name = product.toString()
            object.obj = obj

            // get object bounding box and width for proper placement from right side of the wall
            let objectBox = new THREE.Box3().setFromObject(object)
            objectBox.applyMatrix4(scene.matrixWorld)
            let objectWidth = Math.abs(objectBox.min.x) + Math.abs(objectBox.max.x)


            let minXplacement = -0.298
            let maxXplacement = 0.298

            if (activeStandType.name === 'standalone') {
                object.userData.limit = {
                    min: new THREE.Vector3(minXplacement + width / 2, 0.15, objectDefaultZ),
                    max: new THREE.Vector3(maxXplacement - width / 2, 2, objectDefaultZ)
                };
            }


            functions.updateModelLimits(object)

            // instert into constraints array for live position updates
            objectsMoveConstraints.push(object);

            // instert into draggable array
            objects.push(object);
            addedObjects.push(object);

            let hoverTime

            // creates unique dragControls instance that can be targeted for dispose upon model unload
            dragInstance['drag' + object.id] = new DragControls(objects, camera, renderer.domElement);
            dragInstance['drag' + object.id].transformGroup = true

            let isDragging = false;

            dragInstance['drag' + object.id].addEventListener('dragstart', function (event) {
                Livewire.dispatch('model-activated', { visual_id: object.id, shop_id: object.name, obj_id: object.obj, colorVariation: object.userData.colorVariation, switchMode: object.userData.switchMode })

                oControls.enablePan = false;

                if (!mobile) {
                    $("#object_controls_container").removeClass('active');
                }

                if (mobile) {
                    showMobileControls()
                }

                if (activeStandType.name === 'standalone') {
                    object.userData.limit = {
                        min: new THREE.Vector3(minXplacement + width / 2, 0.23, objectDefaultZ + 0.05),
                        max: new THREE.Vector3(maxXplacement - width / 2, parseFloat(params[3]), objectDefaultZ + 0.05)
                    };
                }
                functions.updateModelLimits(object)
            });

            dragInstance['drag' + object.id].addEventListener('drag', function (event) {


                isDragging = true;
                $("#object_controls_container").removeClass('active');


            });

            dragInstance['drag' + object.id].addEventListener('dragend', function (event) {

                // turn camera pan on
                oControls.enablePan = true;

                // show object controls bar on mobile
                if (mobile) {
                    showMobileControls()
                }

                // update object positioning limits
                if (activeStandType.name === 'standalone') {
                    object.userData.limit = {
                        min: new THREE.Vector3(minXplacement + width / 2, 0.23, objectDefaultZ),
                        max: new THREE.Vector3(maxXplacement - width / 2, parseFloat(params[3]), objectDefaultZ)
                    };
                }
                functions.updateModelLimits(object)

                // snap to panel
                object.position.y = functions.snapToPanel(object.position.y) - 0.004

                // move object from one wall group to another wall group if applicable
                let placeWall = wallsArray.find(wall => wall.xMin < object.position.x && wall.xMax > object.position.x)
                placeWall.group.add(object)
            });

            function showMobileControls() {
                Livewire.dispatch('model-activated', { visual_id: object.id, shop_id: object.name, obj_id: object.obj, colorVariation: object.userData.colorVariation, switchMode: object.userData.switchMode })
                $("#object_controls_container").addClass('mobile-active')
            }

            if (!mobile) {
                dragInstance['drag' + object.id].addEventListener('hoveron', function (event) {
                    Livewire.dispatch('model-activated', { visual_id: object.id, shop_id: object.name, obj_id: object.obj, colorVariation: object.userData.colorVariation, switchMode: object.userData.switchMode })

                    hoverTime = setTimeout(function () {
                        trueHover = true
                    }, 250)

                    if (!zoom) {
                        setTimeout(function () {
                            if (trueHover === true) {
                                $("#object_controls_container").css({ top: pointer.yNorm - 10, left: pointer.xNorm + 20 });

                                if (!placementInProgress) {
                                    $("#object_controls_container").addClass('active')
                                }

                                if (activeStandType.name === 'standalone') {
                                    object.userData.limit = {
                                        min: new THREE.Vector3(minXplacement + width / 2, 0.23, objectDefaultZ),
                                        max: new THREE.Vector3(maxXplacement - width / 2, parseFloat(params[3]), objectDefaultZ)
                                    };
                                }
                                functions.updateModelLimits(object)

                                gsap.fromTo(object.position, {
                                    z: 0,
                                },
                                    {
                                        z: 0.05,
                                        duration: .2
                                    })
                            }
                        }, 500)
                    }

                });
            }



            dragInstance['drag' + object.id].addEventListener('hoveroff', function (event) {

                clearTimeout(hoverTime);
                trueHover = false
                setTimeout(function () {
                    if (controlsHovered === false) {
                        $("#object_controls_container").removeClass('active');
                        Livewire.dispatch('model-deactivated')
                    }
                }, 300)

                gsap.fromTo(object.position, {
                    z: 0.05,
                },
                    {
                        z: 0,
                        duration: .2
                    })
                setTimeout(function () {
                    if (activeStandType.name === 'standalone') {
                        object.userData.limit = {
                            min: new THREE.Vector3(minXplacement + width / 2, 0.23, objectDefaultZ),
                            max: new THREE.Vector3(maxXplacement - width / 2, parseFloat(params[3]), objectDefaultZ)
                        };
                    }
                    functions.updateModelLimits(object)
                }, 200)
            });

            if (!configPositions && !ai) {
                Livewire.dispatch('model-activated', { visual_id: object.id, shop_id: object.name, obj_id: object.obj, colorVariation: object.userData.colorVariation, switchMode: object.userData.switchMode })
                placeObject(object, offset, originalObjId)
                scene.add(object);
                console.log(scene)
            } else if (configPositions) {
                object.position.x = configPositions[0]
                object.position.y = configPositions[1]
                object.position.z = configPositions[2]
                scene.add(object);
                configModelsLoaded++
            } else if (ai) {
                console.log('ai generated')
                //placeAiObject(object, width, height)
            }

        },
        function (xhr) {
            ModelloadingInProgress = true
            Livewire.dispatch('model-loading', xhr.loaded / xhr.total * 100)
        },
        function (error) {
            //console.log(error);
        }
    );

}






$("#object_controls_container").on('mouseenter', () => {
    controlsHovered = true;
})

$("#object_controls_container").on('mouseleave', () => {
    $("#object_controls_container").removeClass('active');
    controlsHovered = false;
})

// initial placement function, snap to mouse position, release and snap to Y grid lines on click
let placementInProgress = false
function placeObject(object, offset, originalObjId) {
    placementInProgress = true
    let placing = true
    if (!mobile) {
        function followMouse() {
            if (placing === true) {
                var vector = new THREE.Vector3(pointer.x, pointer.y, 0.5);
                vector.unproject(camera);
                var dir = vector.sub(camera.position).normalize();
                var distance = - camera.position.z / dir.z;
                var pos = camera.position.clone().add(dir.multiplyScalar(distance));
                pos.z = .145 + 0.075
                object.position.copy(pos);
            }
        }

        window.addEventListener('mousemove', function (event) {
            event.preventDefault();
            followMouse()
        });

        //followMouse()

        window.addEventListener("mouseup", () => {
            if (placementInProgress === true) {
                object.position.y = functions.snapToPanel(object.position.y) - 0.004
            }

            placementInProgress = false

            if (placing === true) {
                // add object to group with active wall
                let placeWall = wallsArray.find(wall => wall.xMin < object.position.x && wall.xMax > object.position.x)
                placeWall.group.add(object)
            }

            placing = false

        });
    } else {
        // placing duplicate model next to original
        if (originalObjId) {
            for (let i = 0; wallsArray.length; i++) {
                wallsArray[i].group.traverse(function (child) {
                    if (child.id === originalObjId) {
                        let tempBoundingBox = new THREE.Box3().setFromObject(child)
                        tempBoundingBox.applyMatrix4(scene.matrixWorld)

                        let originalObjectWidth = Math.abs(tempBoundingBox.min.x) + tempBoundingBox.max.x

                        object.position.x = child.position.x + originalObjectWidth + .2
                        object.position.y = functions.snapToPanel(child.position.y)

                        let placeWall = wallsArray.find(wall => wall.xMin < object.position.x && wall.xMax > object.position.x)
                        placeWall.group.add(object)
                    }
                })
            }
        } else {
            object.position.y = functions.snapToPanel(0)
            object.position.x = 0
            let placeWall = wallsArray.find(wall => wall.xMin < object.position.x && wall.xMax > object.position.x)
            placeWall.group.add(object)
        }

        placementInProgress = false

        placing = false
    }
}

let modelPartRotation = false
// rotate model function
Livewire.on('rotateModel', ({ id }) => {
    if (!modelPartRotation) {
        modelPartRotation = true
        let sceneModel = scene.getObjectById(id)

        if (sceneModel.getObjectByName('kolo_rotate')) {
            let modelPart = sceneModel.getObjectByName('kolo_rotate')

            gsap.fromTo(modelPart.rotation, {
                z: modelPart.rotation.z
            }, {
                z: -modelPart.rotation.z,
                duration: 1
            })
        }

        if (sceneModel.getObjectByName('kolo_rotate_y')) {
            let modelPart = sceneModel.getObjectByName('kolo_rotate_y')

            gsap.fromTo(modelPart.rotation, {
                y: modelPart.rotation.y
            }, {
                y: sceneModel.userData.rotation === 1 ? Math.PI : 0,
                duration: 1
            })
        }

        sceneModel.userData.rotation = -sceneModel.userData.rotation

        setTimeout(() => {
            modelPartRotation = false
        }, 1005);
    }


})

// delete single object/product
async function unloadSingleModel(id, source) {

    if (mobile) {
        $("#object_controls_container").removeClass('mobile-active');
    } else {
        $("#object_controls_container").removeClass('active');
    }

    objectControlsActive = false
    let noOfWalls = wallsArray.length

    for (let i = 0; i <= wallsArray.length - 1; i++) {
        wallsArray[i].group.traverse(function (child) {
            if (child.id === id) {
                dragInstance['drag' + id].dispose()
                wallsArray[i].group.remove(child)
                return new Promise(resolve => {
                    Livewire.dispatch('delete-process-finished')
                    resolve()
                });
            }
        })
    }

    Livewire.dispatch('delete-process-finished')
}

Livewire.on('remove-model', ({ id }) => {
    unloadSingleModel(id)
})

// delete all objects containing certain product
let childrenToRemove = [];
function unloadAllModels(name, priceId) {
    Livewire.dispatch('delete-process-finished')
    if (wallsArray.length > 1) {
        for (let i = 0; i <= wallsArray.length - 1; i++) {
            wallsArray[i].group.children.forEach(child => {
                if (child.name === name && child.userData.priceId === priceId) {
                    childrenToRemove.push(child);
                }
            });
            childrenToRemove.forEach(child => {
                wallsArray[i].group.remove(child);
                dragInstance['drag' + child.id].dispose()
            });
        }
        childrenToRemove = []
    } else {
        wallsArray[0].group.children.forEach(child => {

            if (child.name === name && child.userData.priceId === priceId) {
                childrenToRemove.push(child);
            }
        });
        childrenToRemove.forEach(child => {
            wallsArray[0].group.remove(child);
            dragInstance['drag' + child.id].dispose()
        });
        childrenToRemove = []
    }

    $(".detail_container").removeClass('active');


}

Livewire.on('remove-models', ({ id, priceId }) => {
    unloadAllModels(id.toString(), priceId)
})

function resetCart() {
    Livewire.dispatch('delete-process-finished')
    for (let i = 0; i <= wallsArray.length - 1; i++) {
        wallsArray[i].group.children.forEach(child => {
            if (child.name !== 'panel') {
                childrenToRemove.push(child);
            }
        });
        childrenToRemove.forEach(child => {
            wallsArray[i].group.remove(child);
            dragInstance['drag' + child.id].dispose()
        });
    }

    $(".detail_container").removeClass('active');

}

Livewire.on('reset-cart', () => {
    resetCart()
})


const particles = new THREE.Group();
if (!mobile) {
    // dust particles
    const particleCount = 1500;


    const particleGeometry = new THREE.TetrahedronGeometry(0.0025, 0);
    const particleMaterial = materials.particlesMat

    for (let i = 0; i < particleCount; i++) {
        const particle = new THREE.Mesh(particleGeometry, particleMaterial);

        // Randomize particle positions within a cube
        particle.position.x = Math.random() * 10 - 5;
        particle.position.y = Math.random() * 10 - 5;
        particle.position.z = Math.random() * 10 - 5;

        particles.add(particle);
    }

    scene.add(particles);
}

let dt, lastframe = Date.now()
let zoomFactor;
let loadedModelsUpdated = false
// animate
function animate() {

    //oControls.maxPan.y = functions.clamp(4 - camera.position.z * 2, -0.7, 1.5);
    oControls.maxPan.y = -0.7
    oControls.minPan.y = -oControls.maxPan.y;

    //oControls.minPan.x = functions.clamp(-(4 - camera.position.z * 1.25), -1.5, 0);
    oControls.minPan.x = 0
    oControls.maxPan.x = 0

    if (lastWallX > 2) {
        oControls.maxPan.x = (lastWallX - 1.2) + (4 - camera.position.z * 1.25) / 3;
    } else {
        oControls.maxPan.x = (lastWallX - 1.925) + (4 - camera.position.z * 1.25) / 3;
    }


    interactionManager.update();

    objectsMoveConstraints.forEach(o => {
        o.userData.update();
    })

    dt = (Date.now() - lastframe) / 1000

    if (mixer) {
        mixer.update(dt)
    }

    lastframe = Date.now()

    measurements.xTextValue.sync()
    measurements.yTextValue.sync()

    // Rotate particles
    // particles.rotation.x += 0.0005;
    // particles.rotation.y += 0.0005;

    // Update particle positions for subtle movement
    if (!mobile && particles) {
        particles.children.forEach((particle) => {
            particle.position.x += Math.sin(Date.now() * 0.0001) * 0.0005;
            particle.position.y += Math.cos(Date.now() * 0.0001) * 0.0005;
            particle.position.z += Math.sin(Date.now() * 0.0001) * 0.0005;
            particle.rotation.y += 0.005;
            particle.rotation.x += 0.005;

            // Wrap particles around the scene
            if (particle.position.x < -5 || particle.position.x > 5 ||
                particle.position.y < -5 || particle.position.y > 5 ||
                particle.position.z < -5 || particle.position.z > 5) {
                particle.position.x = Math.random() * 10 - 5;
                particle.position.y = Math.random() * 10 - 5;
                particle.position.z = Math.random() * 10 - 5;
            }
        });
    }

    // update loaded configuration models    
    if (configModelsCount > 0 && loadedModelsUpdated === false) {
        if (configModelsCount === configModelsLoaded) {
            if (wallsArray) {
                updateLoadedModelsWallGroup()
                loadedModelsUpdated = true
            }
        }
    }

    requestAnimationFrame(animate);
    scene.updateMatrixWorld()
    camera.updateProjectionMatrix();
    oControls.update();
    renderer.render(scene, camera);
    //composer.render();
}

animate();
autoresize(camera, renderer);

// UI functions ...

Livewire.on('play-intro-animation', () => {

    configurationToLoad ? 0 : 5.25
    // after loading is complete
    $('#circles_container > img').addClass('active')
    setTimeout(function () {
        $('.inner_circle').addClass('introAnimation')
    }, 5000)

    gsap.to('.inner_circle', { width: '60%', opacity: 1, duration: .8, ease: "back.out(3)", delay: configurationToLoad ? 0 : 5.25 }) // delay 6.25
    gsap.to('.outer_circle', { width: '82%', opacity: 1, duration: .8, ease: "back.out(3)", delay: configurationToLoad ? 0 : 5.5 })
    gsap.to('.button_rotation_container', { opacity: 1, duration: .2, delay: configurationToLoad ? 0 : 5.5 }) // 6.5
    //gsap.to('.object_select_container', { left: '0%', duration: .8, ease: "power3.out", delay: configurationToLoad ? 0 : 4.25 })
    if (!mobile) {
        gsap.fromTo('.cart_component', {
            right: '0px',
            opacity: 0
        }, {
            right: '48px',
            opacity: 1,
            duration: .8,
            ease: "power3.out",
            delay: configurationToLoad ? 0 : 5.75
        })
    }

    gsap.fromTo('.inquiry_btn', {
        right: '2%',
        opacity: 0
    }, {
        right: '4.6%',
        opacity: 1,
        duration: .8,
        ease: "power3.out",
        delay: configurationToLoad ? 0 : 6
    })

    gsap.fromTo('.entry-button', {
        y: '16px',
        opacity: 0
    }, {
        y: '0px',
        opacity: 1,
        duration: 0.8,
        ease: "power3.out",
        delay: 6.75
    })
    gsap.from('.scene_controller_ui', { bottom: '-7.5vw', duration: .8, ease: "power3.out", delay: configurationToLoad ? 0 : 5.75 })
})

// adding objects to the scene
let activatedProduct
$(document).on('click', '.object_btn', function (e) {
    e.preventDefault()
    let model = $(this).data('model')
    let obj = $(this).data('obj')
    let product = $(this).data('product')
    let params = $(this).data('params').split(", ")
    let offset = $(this).data('offset')
    let loadBar = $(this).children('load_bar')
    let priceId = $(this).data('priceid')
    loadModel(model, product, params, offset, obj, null, priceId)
    activatedProduct = product
    ModelloadingInProgress = true
})

Livewire.on('add-duplicate', ({ model: model, product: product, parameters: parameters, offset: offset, obj: obj, colorVariation: colorVariation, priceId: priceId, originalObjId: originalObjId }) => {
    $("#object_controls_container").removeClass('active');
    model = 'images/' + model
    parameters = parameters.split(", ")
    offset = parseFloat(offset)
    loadModel(model, product, parameters, offset, obj, colorVariation, priceId, originalObjId)
    activatedProduct = product
    ModelloadingInProgress = true
})

Livewire.on('model-loading', (progress) => {
    $('#model_load_indicator').addClass('active')
    $('#indicator').width(progress + '%')
    if (progress == 100) {
        setTimeout(function () {
            $('#indicator').addClass('loaded')
            $('#model_load_indicator').removeClass('active')
            ModelloadingInProgress = false
        }, 250)
        setTimeout(function () {
            $('#indicator').width('0%')
            $('#indicator').removeClass('loaded')
            ModelloadingInProgress = false
        }, 1000)
    }
})

Livewire.on('mini-model-loading', (progress) => {
    $('#mini_model_load_indicator').addClass('active')
    $('#mini_indicator').width(progress + '%')
    if (progress == 100) {
        setTimeout(function () {
            $('#mini_indicator').addClass('loaded')
            $('#mini_model_load_indicator').removeClass('active')
            ModelloadingInProgress = false
        }, 250)
        setTimeout(function () {
            $('#mini_indicator').width('0%')
            $('#mini_indicator').removeClass('loaded')
            ModelloadingInProgress = false
        }, 1000)
    }
})

window.addEventListener('pointermove', onPointerMove);

$("#object_controls_container").on('mouseenter', (e) => {
    objectControlsActive = true
})

$("#object_controls_container").on('mouseleave', (e) => {
    objectControlsActive = false
})

document.body.addEventListener('mousemove', (e) => {
    oControls.handleMouseMoveRotate(e)
});

window.addEventListener('moving', function (event) {
    if (!mobile) {
        $("#object_controls_container").css({ top: pointer.yNorm - 10, left: pointer.xNorm + 20 });
    }
});

// switch sides
let side = 'front';

$('#switch_side').on('click', function () {
    if (side === 'front') {
        oControls.minAzimuthAngle = -(Math.PI * 5) // 20
        oControls.maxAzimuthAngle = (Math.PI * 5)  // 20
        //directLight.position.set(0, 5, -3);
        gsap.to(backLight, {
            intensity: 3, // Target Z value you want to animate to
            duration: 1, // Duration of the animation in seconds
            ease: "power2.inOut" // Optional easing function for smooth animation
        });
        setTimeout(() => {
            oControls.minAzimuthAngle = -((Math.PI * 5) - 2) // 20
            oControls.maxAzimuthAngle = ((Math.PI * 5) - 2)  // 20
            side = 'back'
        }, 1000);

    } else {
        gsap.to(backLight, {
            intensity: 0, // Target Z value you want to animate to
            duration: 1, // Duration of the animation in seconds
            ease: "power2.inOut" // Optional easing function for smooth animation
        });
        setTimeout(() => {
            oControls.minAzimuthAngle = -(Math.PI / 5) // 20
            oControls.maxAzimuthAngle = (Math.PI / 5)  // 20
            side = 'front'
        }, 1000);

    }
    oControls.rotateLeft()

})

// zoom to model
let zoom = false
let zoomObjectId = null
let originalCameraPosition
let zoomToObject
Livewire.on('zoom-to-model', ({ id }) => {

    let bbox

    if (id !== null) { zoomToObject = scene.getObjectById(id) }

    if (zoom === false) {

        dragInstance['drag' + zoomToObject.id].deactivate()

        originalCameraPosition = camera.position.x

        oControls.maxPan = new THREE.Vector3(lastWallX + 3, 2, 8.75);
        oControls.minPan = new THREE.Vector3(-2, -2, 0);
        oControls.enablePan = true;
        oControls.minAzimuthAngle = -(Math.PI / .2) // 20
        oControls.maxAzimuthAngle = (Math.PI / .2)  // 20

        zoomToObject.children.forEach(parent => {
            if (parent.name === 'metal' || parent.name.indexOf('plastic') !== 0) {
                bbox = new THREE.Box3().setFromObject(parent);
                bbox.applyMatrix4(scene.matrixWorld)
            }
            parent.children.forEach(child => {
                if (child.name === 'metal' || child.name.indexOf('plastic') !== 0) {
                    bbox = new THREE.Box3().setFromObject(child);
                    bbox.applyMatrix4(scene.matrixWorld)
                }
            });
        });

        //toggleObjects(false)

        zoomInTimeline(bbox.max.x - 0.15, bbox.max.y - 0.11, bbox.max.z - 0.2, 1)
        gsap.to(camera, { fov: 25, duration: 1 })

        $('.zoomOut_btn').addClass('active')
        $("#object_controls_container").removeClass('active');
        $(".detail_container").addClass('active');
        $(".object_select_container").css({ left: '-18%' });
        $(".button_rotation_container, #circles_container").addClass('zoom');

        zoomObjectId = zoomToObject.name

        zoom = !zoom

    } else {

        dragInstance['drag' + zoomToObject.id].activate()

        gsap.to(camera, { fov: 35, duration: 1.5, ease: "Power2.easeInOut" })


        gsap.to(oControls.minPan, {
            x: 0,
            y: 0,
            z: 0,
            duration: 2,
            ease: "Power2.easeInOut"
        })

        gsap.to(oControls.maxPan, {
            x: lastWallX - 1.9718,
            y: 0,
            z: 0,
            duration: 2,
            ease: "Power2.easeInOut"
        })

        zoomInTimeline(originalCameraPosition, 0, 0, 8.75)

        oControls.enablePan = true;


        //toggleObjects(true)



        setTimeout(function () {
            oControls.minAzimuthAngle = -(Math.PI / 20) // 20
            oControls.maxAzimuthAngle = (Math.PI / 20)  // 20

        }, 1500);

        $('.zoomOut_btn').removeClass('active')
        $(".object_select_container").css({ left: '0%' });
        $(".detail_container").removeClass('active');
        $(".button_rotation_container, #circles_container").removeClass('zoom');

        zoomObjectId = null

        zoom = !zoom
    }
})

function zoomInTimeline(x, y, z, zoomOutFactor = 0) {
    gsap.to(camera.position, { x: x + 1, y, z: z + zoomOutFactor, duration: 1.5, ease: "Power2.easeInOut" })
    gsap.to(oControls.target, { x, y, z, duration: 1.5, ease: "Power2.easeInOut" })

};

$("#zoom_info_btn").on('click', function (e) {
    e.preventDefault()
    Livewire.dispatch('view-detail', { id: zoomObjectId })
})

function toggleObjects(state) {
    for (let i = 0; i < addedObjects.length; i++) {
        addedObjects[i].traverse(function (child) {
            if (child instanceof THREE.Mesh) {
                if (child.name.indexOf('metal') !== 0 && child.name.indexOf('rubber') !== 0 && child.name.indexOf('fortis') !== 0 && child.name.indexOf('plastic') !== 0 && child.name.indexOf('tag') !== 0) {
                    child.visible = state
                }
            }
        })
    }

    setTimeout(function () {
        objectsVisible = state
    }, 100);
}

$('.hideObjs').on('click', (e) => {
    e.preventDefault()
    if (objectsVisible === true) {
        toggleObjects(false)
    } else if (objectsVisible === false) {
        toggleObjects(true)
    }
})

$('#main_add_btn').on('click', (e) => {
    if (mobile) {
        $("#object_controls_container").removeClass('mobile-active');
        $("#scene_controller_ui").removeClass('active');
        Livewire.dispatch('model-deactivated')
    }
})

$('.zoomOut_btn').on('click', (e) => {
    e.preventDefault()
    Livewire.dispatch('zoom-to-model', { id: null })
})


Livewire.on('change-color-variation', ({ objectId, colorName, priceId }) => {

    let objectToUpdate = scene.getObjectById(objectId);
    objectToUpdate.userData.colorVariation = colorName;
    objectToUpdate.userData.priceId = parseInt(priceId);

    let metalTempColor = new THREE.Color().setHSL(0, 0, 0);
    let rubberTempColor = new THREE.Color().setHSL(0, 0, 0);
    let plasticTempColor = new THREE.Color().setHSL(0, 0, 0);

    if (colorName === 'steel') {
        metalTempColor.setHex('0xF3F0EA');
        rubberTempColor.setHex('0x000000');
    }

    if (colorName === 'black') {
        metalTempColor.setHex('0x2B2B2C');
        rubberTempColor.setHex('0x7F786A');
        plasticTempColor.setHex('0x000000');
    }

    if (colorName === 'white') {
        plasticTempColor.setHex('0xBEBEBE');
        metalTempColor.setHex('0xFFFFFF');
    }

    if (colorName === 'graphite') {
        plasticTempColor.setHex('0x111111');
    }

    if (colorName === 'grey') {
        plasticTempColor.setHex('0x666666');
    }

    if (colorName === 'red') {
        plasticTempColor.setHex('0xff0000');
    }

    if (colorName === 'blue') {
        plasticTempColor.setHex('0x00197E');
    }

    if (colorName === 'transparent') {
        plasticTempColor.setHex('0xffffff');
    }

    objectToUpdate.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
            if (child.name.indexOf('metal') !== -1) {
                gsap.to(child.material.color, {
                    r: metalTempColor.r,
                    g: metalTempColor.g,
                    b: metalTempColor.b,
                    duration: .2
                })

                if (colorName === 'white') {
                    child.material.metalness = 0.65;
                } else {
                    child.material.metalness = 1;
                }
            }
            if (child.name.indexOf('fortis') !== -1) {

                if (colorName === 'white') {
                    child.material.metalness = 0.65;
                } else {
                    child.material.metalness = 1;
                }
            }
            if (child.name.indexOf('rubber') !== -1) {
                gsap.to(child.material.color, {
                    r: rubberTempColor.r,
                    g: rubberTempColor.g,
                    b: rubberTempColor.b,
                    duration: .2
                })
            }
            if (child.name.indexOf('plastic') !== -1) {
                gsap.to(child.material.color, {
                    r: plasticTempColor.r,
                    g: plasticTempColor.g,
                    b: plasticTempColor.b,
                    duration: .2
                })
                if (colorName === 'transparent') { child.material.transmission = 0.8 } else { child.material.transmission = 0 }
            }
        }
    })
    //Livewire.dispatch('update-color-variation', { product_id: objectToUpdate.name, new_price_id: priceId, variation_color: colorName, variation_id: variationId })
})

// fullscreen control
let fullscreenMode = false
$(".fullscreen_btn, .fs-ico").on('click', () => {
    if (!fullscreenMode) {
        fullscreen.openFullscreen()
    } else {
        fullscreen.closeFullscreen()
    }
    fullscreenMode = !fullscreenMode
})

// measurements display
let measurementsState = false
$('.measure_btn').on('click', function (e) {
    e.preventDefault()

    let center
    let wallsLength
    let xMin
    let xMax

    if (wallsArray.length > 1) {
        xMin = wallsArray[0].xMin
        xMax = wallsArray[wallsArray.length - 1].xMax
        center = ((Math.abs(xMin) + xMax) / 2) - 1 - Math.abs(xMin) / 2
        wallsLength = Math.abs(xMin) + xMax
    } else {
        center = 0
        xMin = wallsArray[0].xMin
        xMax = wallsArray[0].xMax
        wallsLength = Math.abs(xMin) + xMax
    }

    if (!measurementsState) {
        measurements.getMeasurements(scene, 'get', center, systemXValue.text, xMin, xMax, rightClipPlane[0].constant)
        measurementsState = true
    } else {
        measurements.resetMeasurements()
        measurementsState = false
    }
})

//screenshot capture
$('.screenshot_btn').on('click', function () {

    let zFactor
    let center

    // if (wallsArray.length > 1) {
    //     let xMin = wallsArray[0].xMin
    //     let xMax = wallsArray[wallsArray.length - 1].xMax
    //     center = ((Math.abs(xMin) + xMax) / 2) - 1 - Math.abs(xMin) / 2
    //     zFactor = (Math.abs(xMin) + xMax) * 0.36
    //     zoomInTimeline(center, 0.2, 0, 7 + zFactor)
    // } else {
    //     zoomInTimeline(0, 0.2, 0, 7)
    // }

    if (wallsArray.length === 2) {
        zoomInTimeline(1, 0, 0, 8.5)
    } else if ((wallsArray.length === 3)) {
        zoomInTimeline(3, 0, 0, 12)
    } else if ((wallsArray.length === 4)) {
        zoomInTimeline(5, 0, 0, 14.5)
    } else if ((wallsArray.length === 5)) {
        zoomInTimeline(7, 0, 0, 17.5)
    } else if ((wallsArray.length > 5)) {
        zoomInTimeline(9, 0, 0, 19)
    } else {
        zoomInTimeline(-1, 0, 0, 8.5)
    }

    // zoom-in animations
    gsap.to(oControls, {
        minAzimuthAngle: 0,
        maxAzimuthAngle: 0,
        duration: 1.5,
        ease: "Power2.easeInOut"
    })

    // effects animations
    let frameDuration = 1.2
    let frameDelay = .8
    let frameEasing = "elastic.out(1.2,0.5)"
    gsap.to('#main_ui', { opacity: 0, scale: 1.05, duration: .5 })
    gsap.to('#screenshot_container', { opacity: 1, duration: .1 })
    gsap.to('.top_left', { top: '80px', left: '80px', opacity: 1, duration: frameDuration, delay: frameDelay, ease: frameEasing })
    gsap.to('.top_right', { top: '80px', right: '80px', opacity: 1, duration: frameDuration, delay: frameDelay, ease: frameEasing })
    gsap.to('.bottom_right', { bottom: '80px', right: '80px', opacity: 1, duration: frameDuration, delay: frameDelay, ease: frameEasing })
    gsap.to('.bottom_left', { bottom: '80px', left: '80px', opacity: 1, duration: frameDuration, delay: frameDelay, ease: frameEasing })
    gsap.to('#screenshot_container > .center_element > .outer', { scale: 1, opacity: 1, duration: frameDuration, delay: frameDelay, ease: frameEasing })
    gsap.to('#screenshot_container > .center_element > .inner', { rotation: 0, opacity: 1, duration: frameDuration, delay: frameDelay, ease: frameEasing })
    gsap.to('.flash', { opacity: .1, duration: 0.1, delay: frameDelay + .6 })
    gsap.to('.flash', { opacity: 0, duration: 0.1, delay: frameDelay + .65 })
    gsap.to('#screenshot_container', { opacity: 0, duration: .5, delay: frameDelay + 1.3 })
    gsap.to('#main_ui', { opacity: 1, scale: 1, duration: .5, delay: frameDelay + 2 })
    gsap.to('.top_left', { top: '160px', left: '160px', opacity: 0, duration: .1, delay: frameDelay + 2, ease: frameEasing })
    gsap.to('.top_right', { top: '160px', right: '160px', opacity: 0, duration: .1, delay: frameDelay + 2, ease: frameEasing })
    gsap.to('.bottom_right', { bottom: '160px', right: '160px', opacity: 0, duration: .1, delay: frameDelay + 2, ease: frameEasing })
    gsap.to('.bottom_left', { bottom: '160px', left: '160px', opacity: 0, duration: .1, delay: frameDelay + 2, ease: frameEasing })
    gsap.to('#screenshot_container > .center_element > .outer', { scale: .4, opacity: 0, duration: .1, delay: frameDelay + 2, ease: frameEasing })
    gsap.to('#screenshot_container > .center_element > .inner', { rotation: 90, opacity: 0, duration: .1, delay: frameDelay + 2, ease: frameEasing })

    // taking screenshot
    setTimeout(function () {
        let orderButton = document.getElementById('order_btn');
        let orderLink = orderButton.getAttribute('href');

        screenshot.saveAsImage(renderer, cameras, scene, '', orderLink)
    }, 1500)

    // zoom-out animations
    setTimeout(function () {
        gsap.to(oControls, {
            minAzimuthAngle: -(Math.PI / 20),
            maxAzimuthAngle: (Math.PI / 20),
            duration: 1.5,
            ease: "Power2.easeInOut"
        })

        if (wallsArray.length > 1) {
            zoomInTimeline(-1, 0, 0, 8.5)
        } else {
            zoomInTimeline(-1, 0, 0, 8.5)
        }
    }, 2000)
})

// console debug mode switch
let DEBUG = true;
if (!DEBUG) {
    if (!window.console) window.console = {};
    let methods = ["log", "debug", "warn", "info"];
    for (let i = 0; i < methods.length; i++) {
        console[methods[i]] = function () { };
    }
}

// reset file input on form
$(document).on('click', '.reset_files', function (e) {
    const fileInput = document.getElementById("upload");
    fileInput.value = null;
})

$('.order-action, .inquiry_btn').on('click', function () {
    $('#window, #lead_modal').addClass('order-view')
})

$('.form-close-btn').on('click', function () {
    setTimeout(function () {
        $('#window, #lead_modal').removeClass('order-view')
    }, 300)
})

// function to get url parameters

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};

function saveConfiguration(type) {

    let orderButton = document.getElementById('order_btn');
    let orderLink = orderButton.getAttribute('href');
    let screenshotsHash = functions.generateRandomHash(10)

    screenshot.saveAsImage(renderer, cameras, scene, screenshotsHash, orderLink)

    let sceneData = [] // final array object
    let sceneWalls = []
    let sceneObjects = []
    let sceneConfig = {}

    // get data about walls in the scene -> final array position: 0
    wallsArray.forEach(wall => {
        let wallArray = {
            'size': wall.size
        }
        sceneWalls.push(wallArray)
    })
    sceneData.push(sceneWalls)

    // get data about objects in the scene -> final array position: 1
    for (let i = 0; i <= wallsArray.length - 1; i++) {
        wallsArray[i].group.children.forEach(child => {
            if (child.name !== 'wall' && child.name !== 'delete' && child.name !== 'Scene') {
                let objectArray = {
                    'name': child.name, // product/shop id
                    'objId': child.obj, // db obj_id
                    'objSceneId': child.id,
                    'priceId': child.userData.priceId,
                    'path': child.userData.modelPath,
                    'colorVariation': child.userData.colorVariation,
                    'params': child.userData.params,
                    'posX': child.position.x,
                    'posY': child.position.y,
                    'posZ': child.position.z,
                    'rotation': child.userData.rotation
                }
                sceneObjects.push(objectArray)
            }
        });
    }
    sceneData.push(sceneObjects)

    // scene config object -> final array position: 2
    sceneConfig = {
        'lights': nightLights.length,
        'rightPropsX': rightProps.position.x,
        'clipPlaneX': rightClipPlane[0].constant,
        'maxPan': oControls.maxPan.x,
        'panelColor': materials.panelSurface.color.getHexString(),
        'panelColorName': $('#panel-color-name').text()
    }
    sceneData.push(sceneConfig)

    Livewire.dispatch('save-configuration', { sceneData: sceneData, type: type, source: Alpine.store('externalDestination').val, screenshotsHash: screenshotsHash, orderLink: orderLink })
}

$('.submit-configuration').on('click', function (e) {
    e.preventDefault()
    if ($('#conf_terms').is(":checked") && $('#conf_password').val !== '' && $('#conf_email').val !== '') {
        saveConfiguration('new')
    }
})

$(document).on('click', '.submit-auth-configuration', function (e) {
    e.preventDefault()
    saveConfiguration('authorized')
})

$(document).on('click', '.submit-updated-configuration', function (e) {
    e.preventDefault()
    saveConfiguration('updated')
})

// Get configuration data from db
function getConfigurationData(id) {
    Livewire.dispatch('get-configuration-data', { configId: id })
}

// Process configuration data and fill scene

Livewire.on('process-configuration-data', async ({ configData }) => {
    setTimeout(() => {
        setupSceneConfig(configData)
            .then((resolve) => configPanelColor(configData[2].panelColor, configData[2].panelColorName))
            .then((resolve) => loadConfigModels(configData))
    }, 1200);
})

// Partial configuration process function -> load and place all models
async function loadConfigModels(configData) {
    configModelsCount = configData[1].length
    configData[1].forEach(model => {
        let params = model.params.split(",")

        model.rotation ? model.rotation : 1

        let configPositions = [model.posX, model.posY, model.posZ]
        loadModel(model.path, parseInt(model.name), params, 0, model.objId, model.colorVariation, model.priceId, null, configPositions, model.objSceneId, model.rotation)
        activatedProduct = parseInt(model.name)
    })
    return new Promise(resolve => { resolve() });
}

// Partial configuration process function -> setup walls into the scene -> after that return promise to setup models
async function setupSceneConfig(configData) {

    // setup walls -> we do not want to work with the first wall if there are more walls, thus i = 1
    for (let i = 1; i < configData[0].length; i++) {
        await loadWall(configData[0][i].size).then((resolve) => {
            if (i + 1 === configData[0].length) {
                return new Promise(resolve => {
                    setTimeout(() => {
                        resolve();
                    }, 0);
                });
            }
        })
    }

    rightProps.position.x = configData[2].rightPropsX

    // setup camera maxPan if there are more walls in the scene
    if (configData[0].length > 1) {
        oControls.enablePan = true;
    }
}

// add loaded models to their wallgroups
function updateLoadedModelsWallGroup() {
    scene.traverse(function (child) {
        if (child.userData.type === 'model') {
            setTimeout(() => {
                let placeWall = wallsArray.find(wall => wall.xMin < child.position.x && wall.xMax > child.position.x)
                placeWall.group.add(child)
            }, 1000);
        }
    });
}

// Partial configuration process function -> change walls color
async function configPanelColor(color, name) {

    let tempColor = new THREE.Color().setHSL(0, 0, 0);
    tempColor.setHex('0x' + color);
    scene.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
            if (child.name.indexOf('panel') !== -1) {
                if (color === '989ea2') { // dark wood
                    child.material = materials.darkWoodSurface
                }
                if (color === '989ea3') { // light wood
                    child.material = materials.lightWoodSurface
                }
                if (color !== '989ea3' && color !== '989ea2') {
                    gsap.to(child.material.color, {
                        r: tempColor.r,
                        g: tempColor.g,
                        b: tempColor.b,
                        duration: .25
                    })
                }
            }
        }
    });

    $('#color-preview-ico').css('background-color', "#" + color)
    $('#panel-color-name').text(name)
    Livewire.dispatch('config-cart-panel-color', { colorName: name })

    return new Promise(resolve => { resolve() });
}

// copy configuration link to clipboard
const copyLinkContent = async (elementId) => {
    let text = document.getElementById(elementId).innerHTML;
    try {
        await navigator.clipboard.writeText(text);
        $('.copy-btn').css('backgroundColor', 'black')
        $('.copy-btn').html('hotovo')
    } catch (err) {

    }
}

$(document).on('click', '.copy-btn', function (e) {
    copyLinkContent('link-text')
})

$('.copy-btn').on('mouseleave', function () {
    $('.copy-btn').css('backgroundColor', '#2740b8')
    $('.copy-btn').html('kopírovat')
})

Livewire.on('new-configuration-created', ({ hash }) => {

    Livewire.dispatch('authorize-user')
    Alpine.store('auth').toggle()
    Alpine.store('confNo').val = hash
    Livewire.dispatch('get-conf-number', { confNumber: hash })

    configurationHash = hash
    userAuthorized = true

    window.history.pushState("object or string", "ConfigCreate", "/?config=" + hash)

    configurationsArray.push(hash)

    sessionStorage.setItem('configurations', JSON.stringify(configurationsArray))
    sessionStorage.setItem('authorization', '1')
})

Livewire.on('new-auth-configuration-created', ({ hash }) => {

    // Livewire.dispatch('authorize-user')
    // Alpine.store('auth').toggle()

    Alpine.store('confNo').val = hash
    Livewire.dispatch('get-conf-number', { confNumber: hash })

    configurationHash = hash
    userAuthorized = true

    window.history.pushState("object or string", "ConfigCreate", "/?config=" + hash)

    configurationsArray.push(hash)

    sessionStorage.setItem('configurations', JSON.stringify(configurationsArray))
    sessionStorage.setItem('authorization', '1')
})

$(document).on('click', '.search-btn', function (e) {
    document.getElementById("uiSearchField").focus();
});


// mobile menu toggle
$(document).on('click', '#mobile-menu-btn', function (e) {
    $("#scene_controller_ui").toggleClass('active');
    $("#object_controls_container").removeClass('mobile-active');
});

$(document).on('click', '#cart_component, #mobile-zoom-in, #mobile-zoom-out, .panel_add_container', function (e) {
    $("#object_controls_container").removeClass('mobile-active');
});

if (mobile) {
    $("#order_btn").text('Koupit');
}

// mobile zoom function
if (mobile) {
    $(document).on('click', '#mobile-zoom-in', function (e) {
        camera.rotation.y = 45
        camera.position.z -= 1
    });
    $(document).on('click', '#mobile-zoom-out', function (e) {
        camera.position.z += 1
    });
}

window.addEventListener("beforeunload", function (e) {
    e.preventDefault();
    e.returnValue = "";
});

// // save reminder function
// setTimeout(function () {
//     remindSave()
// }, 300000);

// function remindSave() {
//     if (configurationHash === '' && !Alpine.store('conForm').on) {
//         $('#save-reminder-modal-container').addClass('active')
//     }
// }

// $('.reminder-modal-cta, .close-reminder-modal, .conf-form-close-btn').on('click', function () {
//     $('#save-reminder-modal-container').removeClass('active')
//     setTimeout(function () {
//         remindSave()
//     }, 300000);
// })

window.addEventListener('click', function (event) {
    $('.entry-button').css('display', 'none')
});




// AI generation
Livewire.on('process-ai-data', ({ aiModels, systemWidth }) => {
    console.log(systemWidth)
    if (systemWidth !== 'default') {
        if (systemWidth > 200 && systemWidth <= 300) {
            Livewire.dispatch('addWall', { size: 1, mode: 'new' })
        }
        if (systemWidth > 300 && systemWidth <= 400) {
            Livewire.dispatch('addWall', { size: 2, mode: 'new' })
        }
        if (systemWidth > 400 && systemWidth <= 500) {
            Livewire.dispatch('addWall', { size: 2, mode: 'new' })
            Livewire.dispatch('addWall', { size: 1, mode: 'new' })
        }
        if (systemWidth > 500 && systemWidth <= 600) {
            Livewire.dispatch('addWall', { size: 2, mode: 'new' })
            Livewire.dispatch('addWall', { size: 2, mode: 'new' })
        }
    }
    aiModelsCount = aiModels.length
    loadAllAiModels(aiModels, systemWidth);
})

async function loadAllAiModels(models, systemWidth) {
    console.log(models.length)
    Livewire.dispatch('update-ai-model-number', { no: models.length })
    for (const model of models) {
        let params = model.params.split(",")

        model.rotation ? model.rotation : 1
        model.filename = 'images/' + model.filename

        await loadAiModel(model.filename, model.product_id, params, 0, model.obj_id.toString(), null, model.price_id, null, null, null, model.rotation, true)
        activatedProduct = parseInt(model.product_id)
        Livewire.dispatch('update-ai-model-counter')
    }

    let wallSize = 3.92
    let wallCenter = 0
    if (systemWidth !== 'default') {
        wallSize = (systemWidth * 3.92) / 200
        wallCenter = (wallSize / 2) - 1
    }

    arrangeObjectsRandomly(addedObjects, wallSize, 3.7, wallCenter, 1, 0.025, 0.3);
    setTimeout(() => {
        Livewire.dispatch('ai-finished')
    }, 1000);

}

function isCollision(obj1, obj2, spacing) {
    const box1 = new THREE.Box3().setFromObject(obj1).expandByScalar(spacing);
    const box2 = new THREE.Box3().setFromObject(obj2).expandByScalar(spacing);
    return box1.intersectsBox(box2);
}

// Function to generate a random position within the wall boundaries
function getRandomPosition(objWidth, objHeight, wallWidth, wallHeight, spacing, maxCrossing) {
    const minX = -(wallWidth / 2 - objWidth / 2 - spacing);
    const maxX = wallWidth / 2 - objWidth / 2 - spacing;
    const minY = -(wallHeight / 2 - objHeight / 2 - spacing + maxCrossing);
    const maxY = wallHeight / 2 - objHeight / 2 - spacing - maxCrossing;

    const x = Math.random() * (maxX - minX) + minX;
    const y = Math.random() * (maxY - minY) + minY;

    return { x, y };
}

function arrangeObjectsRandomly(objects, wallWidth, wallHeight, wallCenterX, wallCenterY, spacing, maxCrossing) {
    // Sort objects by area (largest to smallest)
    objects.sort((a, b) => {
        const boxA = new THREE.Box3().setFromObject(a);
        const boxB = new THREE.Box3().setFromObject(b);
        const areaA = (boxA.max.x - boxA.min.x) * (boxA.max.y - boxA.min.y);
        const areaB = (boxB.max.x - boxB.min.x) * (boxB.max.y - boxB.min.y);
        return areaB - areaA;
    });

    objects.forEach((obj, index) => {
        const boundingBox = new THREE.Box3().setFromObject(obj);
        const objWidth = boundingBox.max.x - boundingBox.min.x;
        const objHeight = boundingBox.max.y - boundingBox.min.y;

        let position;
        let collision;
        let attempts = 0;
        let successfullyPlaced = false; // Flag to check if object was successfully placed

        // Place tall objects at the top
        if (objHeight > wallHeight / 2) {
            position = { x: (Math.random() * (wallWidth - objWidth) - (wallWidth / 2 - objWidth / 2 - spacing)), y: wallHeight / 2 - objHeight / 2 - spacing };
            obj.position.set(position.x + wallCenterX, position.y + wallCenterY, 0);
            successfullyPlaced = true;
            console.log(`Tall object ${index} placed at the top at (${position.x}, ${position.y})`);
        } else {
            // Try to place the object at a random position without overlapping
            do {
                if (attempts > 1000) {
                    console.error('Could not place object without collision after 1000 attempts.');
                    break;
                }
                position = getRandomPosition(objWidth, objHeight, wallWidth, wallHeight, spacing, maxCrossing);
                obj.position.set(position.x + wallCenterX, position.y + wallCenterY, 0);

                collision = false;
                for (let i = 0; i < index; i++) {
                    if (isCollision(obj, objects[i], spacing)) {
                        collision = true;
                        break;
                    }
                }
                if (!collision) {
                    successfullyPlaced = true; // Object placed successfully

                    obj.position.y = functions.snapToPanel(obj.position.y) - 0.004;
                    let placeWall = wallsArray.find(wall => wall.xMin < obj.position.x && wall.xMax > obj.position.x)
                    placeWall.group.add(obj) // Object placed successfully

                }
                attempts++;
            } while (collision);

            if (successfullyPlaced) {
                console.log(`Object ${index} placed at (${position.x}, ${position.y})`);
            } else {
                console.error(`Failed to place object ${index}`);
            }
        }

        if (successfullyPlaced) {
            obj.position.y = functions.snapToPanel(obj.position.y) - 0.004;
            let placeWall = wallsArray.find(wall => wall.xMin < obj.position.x && wall.xMax > obj.position.x)
            placeWall.group.add(obj); // Add object to the appropriate wall group
        }
    });
}


async function loadAiModel(model, product, params, offset, obj, colorVariation = null, priceId, originalObjId, configPositions, objSceneId, rotation = 1, ai = true) {
    return new Promise(resolve => {
        gLoader.load(
            model,
            function (gltf) {

                let objects = []

                objectsVisible = true;

                let object = gltf.scene

                // data for AI auto-generation
                let boundingBox = new THREE.Box3().setFromObject(object);
                let width = boundingBox.max.x - boundingBox.min.x;
                let height = boundingBox.max.y - boundingBox.min.y;


                object.userData.type = "model"
                object.userData.priceId = priceId
                object.userData.modelPath = model
                object.userData.params = params.toString()
                object.userData.rotation = rotation
                object.userData.switchMode = false

                if (model.includes('kolo') && !model.includes('vidula') && !model.includes('kolobezka_velka') && !model.includes('elektro') && !model.includes('dospele_kolo') && !model.includes('detske_kolo') && !model.includes('kolo_helma')) {
                    object.userData.switchMode = true

                    let modelPart = object.getObjectByName('kolo_rotate')

                    if (object.userData.rotation === -1) {
                        modelPart.rotation.z = -modelPart.rotation.z
                    }
                }

                if (model.includes('dospele_kolo') || model.includes('kolobezka_velka')) {
                    object.userData.switchMode = true

                    let modelPart = object.getObjectByName('kolo_rotate_y')

                    if (object.userData.rotation === 1) {
                        modelPart.rotation.y = 0
                    }

                    if (object.userData.rotation === -1) {
                        modelPart.rotation.y = Math.PI
                    }
                }

                object.traverse(function (child) {
                    if (child instanceof THREE.Mesh) {
                        if (child.name.indexOf('metal') !== -1) {
                            if (model.indexOf('store') !== -1) {
                                object.userData.colorVariation = colorVariation ? colorVariation : 'black'
                            } else {
                                object.userData.colorVariation = colorVariation ? colorVariation : globalColorVariation
                            }

                            let metalMaterial = new THREE.MeshPhysicalMaterial({
                                color: object.userData.colorVariation === 'steel' ? 0xF3F0EA : 0x2B2B2C,
                                metalness: 1,
                                reflectivity: 0.85,
                                roughness: .22,
                                ior: 1.7
                            })

                            child.material = metalMaterial;
                        }
                        if (child.name.indexOf('rubber') !== -1) {
                            object.userData.colorVariation = colorVariation ? colorVariation : globalColorVariation

                            let rubberMaterial = new THREE.MeshPhysicalMaterial({
                                color: object.userData.colorVariation === 'steel' ? 0x000000 : 0x7F786A,
                            })

                            child.material = rubberMaterial;
                        }
                        if (child.name === 'model') {
                            child.material = materials.objectMat;
                            gsap.to(child.material, { opacity: 1, duration: 0.4 })
                        }
                        if (child.name.indexOf('hbox') !== -1) {
                            child.material = materials.hoverMat;
                        }
                        if (child.name.indexOf('fortis') !== -1) {
                            child.material = materials.fortisMat;
                        }
                        if (child.name.indexOf('tag') !== -1) {
                            child.material = materials.tagMaterial;
                        }
                        if (child.name.indexOf('blackmet') !== -1) {
                            child.material = materials.blackMetal;
                        }
                        if (child.name.indexOf('plastic') !== -1) {
                            object.userData.colorVariation = colorVariation ? colorVariation : 'white'

                            let plasticColor
                            let plasticTransmission = 0
                            if (object.userData.colorVariation === 'white') { plasticColor = 0xBEBEBE }
                            if (object.userData.colorVariation === 'grey') { plasticColor = 0x666666 }
                            if (object.userData.colorVariation === 'black') { plasticColor = 0x000000 }
                            if (object.userData.colorVariation === 'red') { plasticColor = 0xff0000 }
                            if (object.userData.colorVariation === 'blue') { plasticColor = 0x00197E }
                            if (object.userData.colorVariation === 'graphite') { plasticColor = 0x111111 }
                            if (object.userData.colorVariation === 'transparent') { plasticColor = 0xffffff, plasticTransmission = 0.1 }

                            let plasticMaterial = new THREE.MeshPhysicalMaterial({
                                color: plasticColor,
                                reflectivity: .6,
                                roughness: 0.25,
                                clearcoat: .15,
                                clearcoatRoughness: 0.15,
                                transmission: plasticTransmission
                            })

                            child.material = plasticMaterial;
                        }
                        if (child.name === 'mic') {
                            child.material.roughness = .6;
                            child.material.metalness = 0;
                            gsap.fromTo(child.rotation, { y: 2 }, { y: 0, duration: 1, ease: "power3.out" })
                        }
                        if (child.name.indexOf('m_met') !== -1) {
                            child.material = materials.objMetal;
                        }
                        if (child.name.indexOf('plastorange') !== -1) {
                            child.material = materials.plasticOrangeMat;
                        }
                        if (child.name.indexOf('m_met_dark') !== -1) {
                            child.material = materials.objMetalDark;
                        }
                        if (child.name.indexOf('m_rub') !== -1) {
                            child.material = materials.objRubber;
                        }
                        if (child.name.indexOf('m_white') !== -1) {
                            child.material = materials.objWhite;
                        }
                        if (child.name.indexOf('m_bike_body') !== -1) {
                            child.material = materials.objDark;
                        }
                        if (child.name.indexOf('m_kufr_body') !== -1) {
                            child.material = materials.kufrBodyMat;
                        }
                        if (child.name !== 'hbox') {
                            child.castShadow = true;
                            child.receiveShadow = true;
                        }

                    }
                });

                let objectDefaultZ = .14475

                let scaleFactor = 1.05
                object.scale.set(scaleFactor, scaleFactor, scaleFactor)

                // pass product iD as object name
                object.name = product.toString()
                object.obj = obj

                Livewire.dispatch('load-product-data', { product_id: object.name, amount: 1, obj_id: object.obj })

                // get object bounding box and width for proper placement from right side of the wall
                let objectBox = new THREE.Box3().setFromObject(object)
                objectBox.applyMatrix4(scene.matrixWorld)
                let objectWidth = Math.abs(objectBox.min.x) + Math.abs(objectBox.max.x)

                // set move contraints on canvas - unique for each model/object - altered by active wall size

                object.userData.limit = {
                    min: new THREE.Vector3(parseFloat(params[0]), parseFloat(params[1]), objectDefaultZ),
                    max: new THREE.Vector3(lastWallX, parseFloat(params[3]), objectDefaultZ)
                };
                functions.updateModelLimits(object)

                // instert into constraints array for live position updates
                objectsMoveConstraints.push(object);

                // instert into draggable array
                objects.push(object);
                addedObjects.push(object);

                let hoverTime

                // creates unique dragControls instance that can be targeted for dispose upon model unload
                dragInstance['drag' + object.id] = new DragControls(objects, camera, renderer.domElement);
                dragInstance['drag' + object.id].transformGroup = true

                let isDragging = false;

                dragInstance['drag' + object.id].addEventListener('dragstart', function (event) {
                    Livewire.dispatch('model-activated', { visual_id: object.id, shop_id: object.name, obj_id: object.obj, colorVariation: object.userData.colorVariation, switchMode: object.userData.switchMode })

                    oControls.enablePan = false;

                    if (!mobile) {
                        $("#object_controls_container").removeClass('active');
                    }

                    if (mobile) {
                        showMobileControls()
                    }

                    object.userData.limit = {
                        min: new THREE.Vector3(parseFloat(params[0]), parseFloat(params[1]), objectDefaultZ + 0.05),
                        max: new THREE.Vector3(lastWallX, parseFloat(params[3]), objectDefaultZ + 0.05)
                        //max: new THREE.Vector3(lastWallX - objectWidth / 2, parseFloat(params[3]), objectDefaultZ + 0.05)
                    };
                    functions.updateModelLimits(object)
                });

                dragInstance['drag' + object.id].addEventListener('drag', function (event) {


                    isDragging = true;
                    $("#object_controls_container").removeClass('active');


                });

                dragInstance['drag' + object.id].addEventListener('dragend', function (event) {

                    // turn camera pan on
                    oControls.enablePan = true;

                    // show object controls bar on mobile
                    if (mobile) {
                        showMobileControls()
                    }

                    // update object positioning limits
                    object.userData.limit = {
                        min: new THREE.Vector3(parseFloat(params[0]), parseFloat(params[1]), objectDefaultZ),
                        max: new THREE.Vector3(lastWallX, parseFloat(params[3]), objectDefaultZ)
                        //max: new THREE.Vector3(lastWallX - objectWidth / 2, parseFloat(params[3]), objectDefaultZ)
                    };
                    functions.updateModelLimits(object)

                    // snap to panel
                    object.position.y = functions.snapToPanel(object.position.y) - 0.004

                    // move object from one wall group to another wall group if applicable
                    let placeWall = wallsArray.find(wall => wall.xMin < object.position.x && wall.xMax > object.position.x)
                    placeWall.group.add(object)
                });

                function showMobileControls() {
                    Livewire.dispatch('model-activated', { visual_id: object.id, shop_id: object.name, obj_id: object.obj, colorVariation: object.userData.colorVariation, switchMode: object.userData.switchMode })
                    $("#object_controls_container").addClass('mobile-active')
                }

                if (!mobile) {
                    dragInstance['drag' + object.id].addEventListener('hoveron', function (event) {
                        Livewire.dispatch('model-activated', { visual_id: object.id, shop_id: object.name, obj_id: object.obj, colorVariation: object.userData.colorVariation, switchMode: object.userData.switchMode })

                        hoverTime = setTimeout(function () {
                            trueHover = true
                        }, 250)

                        if (!zoom) {
                            setTimeout(function () {
                                if (trueHover === true) {
                                    $("#object_controls_container").css({ top: pointer.yNorm - 10, left: pointer.xNorm + 20 });

                                    if (!placementInProgress) {
                                        $("#object_controls_container").addClass('active')
                                    }

                                    object.userData.limit = {
                                        min: new THREE.Vector3(parseFloat(params[0]), parseFloat(params[1]), objectDefaultZ),
                                        max: new THREE.Vector3(lastWallX, parseFloat(params[3]), objectDefaultZ + 0.05)
                                        //max: new THREE.Vector3(lastWallX - objectWidth / 2, parseFloat(params[3]), objectDefaultZ + 0.05)
                                    };
                                    functions.updateModelLimits(object)

                                    gsap.fromTo(object.position, {
                                        z: 0,
                                    },
                                        {
                                            z: 0.05,
                                            duration: .2
                                        })
                                }
                            }, 500)
                        }

                    });
                }



                dragInstance['drag' + object.id].addEventListener('hoveroff', function (event) {

                    clearTimeout(hoverTime);
                    trueHover = false
                    setTimeout(function () {
                        if (controlsHovered === false) {
                            $("#object_controls_container").removeClass('active');
                            Livewire.dispatch('model-deactivated')
                        }
                    }, 300)

                    gsap.fromTo(object.position, {
                        z: 0.05,
                    },
                        {
                            z: 0,
                            duration: .2
                        })
                    setTimeout(function () {
                        object.userData.limit = {
                            min: new THREE.Vector3(parseFloat(params[0]), parseFloat(params[1]), objectDefaultZ),
                            max: new THREE.Vector3(lastWallX, parseFloat(params[3]), objectDefaultZ)
                            //max: new THREE.Vector3(lastWallX - objectWidth / 2, parseFloat(params[3]), objectDefaultZ)
                        };
                        functions.updateModelLimits(object)
                    }, 200)
                });

                object.userData.limit = {
                    min: new THREE.Vector3(parseFloat(params[0]), parseFloat(params[1]), objectDefaultZ),
                    max: new THREE.Vector3(lastWallX, parseFloat(params[3]), 10)
                    //max: new THREE.Vector3(lastWallX - objectWidth / 2, parseFloat(params[3]), objectDefaultZ)
                };
                functions.updateModelLimits(object)

                object.position.x = 0
                object.position.y = 0
                object.position.z = 0.145
                scene.add(object);

                setTimeout(() => {
                    resolve(gltf)
                }, 0);
            },
            function (xhr) {
                ModelloadingInProgress = true
                Livewire.dispatch('ai-model-loading', xhr.loaded / xhr.total * 100)
            },
            function (error) {
                //console.log(error);
            }
        );
    });
}

$('#generateAi').on('click', function () {
    $('.ai-status-bar').addClass('active')
    $('#aiForm').addClass('deactivated')
})

Livewire.on('ai-model-loading', (progress) => {
    if (progress == 100) {

    }
})

Livewire.on('ai-finished', () => {
    gsap.to('#starter-component', { opacity: 0, duration: 1, ease: "Power2.easeInOut" })
    setTimeout(() => {
        Alpine.store('starter').on = false
    }, 1000);
})

// export {
//     getConfigurationData
// }











// function delay(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }

// setTimeout(() => {
//     loadAiModels(aiModels)
// }, 4000);

// function loadAiModels(aiModels) {
//     aiModelsCount = aiModels.length
//     aiModels.forEach(model => {
//         let params = model.params.split(",")

//         model.rotation ? model.rotation : 1

//         loadModel(model.path, parseInt(model.name), params, 0, model.objId, globalColorVariation, model.priceId, null, null, null, model.rotation, true)
//         activatedProduct = parseInt(model.name)
//     })
// }